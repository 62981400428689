.sport-rules-container {
	padding: 20px;
	border-radius: 15px;
	margin-bottom: 40px;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	color: white;
	background-color: var(--footers-colors);
	margin-top: 30px;

    p.title-rules {
        font-size    : 20px;
        margin-bottom: 28px !important;
        color        : #0ed1f1 !important;
    }

}

.sport-rules__table {
	border-collapse: collapse;
}

.sport-rules__table--td1 {
	width: 20%;
	padding: 10px;
	border: 1px solid #0484bd;
    background-color: #0484bd;
}

.sport-rules__table--td2 {
	padding: 5px;
    color: #0ed1f1;
	border: 1px solid white;
    text-align: center;
}

// dopdown
.accordion-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	padding: 10px 10px 10px 10px;
}

.container h1 {
	text-align: center;
	margin-bottom: 30px;
	font-weight: 500;
}

.container h2 {
	font-weight: 500;
}

.accordion {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;

	@include from(l){
		width: 100%;
	}
}

.accordion .a-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 5px;
}

.accordion .a-container .a-btn {
	margin: 0;
	position: relative;
	padding: 15px 30px;
	width: 100%;
	color: #fff;
	font-weight: 600;
	display: block;
	font-weight: 500;
	background-color: #0484bd;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	border-radius: 5px;
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.15), 0 10px 10px -5px rgba(0, 0, 0, 0.1) !important;
}

.accordion .a-container .a-btn span {
	display: block;
	position: absolute;
	height: 14px;
	width: 14px;
	right: 20px;
	top: 18px;
}

.accordion .a-container .a-btn span:after {
	content: "";
	width: 14px;
	height: 3px;
	border-radius: 2px;
	background-color: #fff;
	position: absolute;
	top: 6px;
}

.accordion .a-container .a-btn span:before {
	content: "";
	width: 14px;
	height: 3px;
	border-radius: 2px;
	background-color: #fff;
	position: absolute;
	top: 6px;
	transform: rotate(90deg);
	transition: all 0.3s ease-in-out;
}

.accordion .a-container .a-panel {
	width: 100%;
	color: white;
	transition: all 0.2s ease-in-out;
	opacity: 0;
	height: auto;
	max-height: 0;
	overflow: hidden;
	padding: 0px 10px;
}

.accordion .a-container.active .a-btn {
	// color: $color-fourth;
}

.accordion .a-container.active .a-btn span::before {
	transform: rotate(0deg);
}

.accordion .a-container.active .a-panel {
	padding: 15px 10px 10px 10px;
	opacity: 1;
	max-height: 100%;
	background-color: var(--secondari-color);
}

table.rulesTables{
	border: 1px solid;
	margin-top: 2%;
}

table.rulesTables th{
	border-left: 1px solid;
	border-bottom: 1px solid;
}

table.rulesTables td{
	border-left: 1px solid;
	border-bottom: 1px solid;
}

