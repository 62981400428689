.accordion-container{
	margin-top: 10vh;
	&__active{
		display: flex;
	}
	&__btn-acc {
	  border: none;
	  color: #444;
	  cursor: pointer;
	  padding: 18px;
	  width: 100%;
	  text-align: left;
	  outline: none;
	  font-size: 15px;
	  transition: 0.4s;
	}
	&__btn-acc img{
		margin: 0 auto;
		width: 100vh;
	}
	a{
		display: block;
	}
	.active {
	background-color: #292929;
	}
  
	&__panel {
	padding:  0 18px;
	width: 100%;
	color: #fff;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
	display: flex;
	justify-content: center;
		h1{
			@include from(m){
				padding: 3vh;
			font-size: 3vh;
			}
			padding: 1vh;
			font-weight: 800;
			color: #fff;
			font-size: 1vh;
		}
	}
  
	&__btn-acc:after {
	content: '\02795'; 
	font-size: 13px;
	color: #777;
	float: right;
	margin-left: 5px;
	}
  
	.active:after {
	content: "\2796";
	}
  }
