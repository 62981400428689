.ed-container{

    h1.titlle-join {
        margin: 0 auto;
        width: 60%;
        color: #fff;
        padding: 25px;
        font-weight: 700;
        font-size: 30px;
    }
    
    .ed-item.join {
        background-color: #ffffff29;
        padding         : 20px;
        color           : #fff;
        margin: 0 auto;
        p.text-join{
            text-align: center;
        }
    }
    .ed-item.join{

        .title-join{
            text-align: center;
        }
    
        p.text {
            margin: 2%;
        }
        
    }

    p.text-second {
        color: #fff;
        margin: 0 auto;
        padding: 10px;
    }
    
    
}
