footer{
	background-color: #292929;
	ul{
		list-style: none;
		display: flex;
	}
	li{
		margin: 0 0.5rem;
	}
	.sublogo{
		width: 14vh;
    	height: 5vh;
		margin: 0 auto;
		@include from(m){
			margin: 0;
		}
		
		}
	.footer__nav{
			list-style: none;
			display: block;
			justify-content: center;
			text-align: center;
			margin-top: 1rem;
			@include from(m){
				display: flex;
				justify-content: center;
				text-align: center;
			}
		a{
			
			margin: auto 0.1rem;
			text-decoration: none;
			line-height: 0cm;
		}
		:hover{
			color: #f60;
		}
	
	}
	.footer__legal{
	display: flex;
	text-decoration: none;
	list-style: none;
	text-align: center;
	justify-content: center;	
		a{
			text-align: center;
			justify-content: center;
			margin: auto 0;
		}
		.textFooter:last-child{
			padding: 4px;
			font-size: 16px;
			font-weight: 800;
			width: 45px;
			height: 40px;
			display: inline-grid;
			border: 2px solid #f60;
			border-radius: 20px;
			}
		}
}
