.gallery{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	a{
		display: block;
		min-width: 20%;
		width: 100%;
		@include from(m){
			width: 50%;
		}
		@include from(l){
			width: 20%;
		}
	}
		img{
			margin: 0.2rem 0;
		}
}
