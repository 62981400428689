.tns-single{
	& > div:nth-child(even){
		background-image        :url(/src/img/banner/LakersVSPistons_banner.png);
	}
	&__content{
		min-height: 25vh;
		display           : flex;
		justify-content   : center;
		flex-direction    : column;
		align-items       : center;
		span{color        : white;}
	}
}


@include from(m){
	#tnsSingle img{
		max-width: 100%;
		background-color: red;
	}
}





