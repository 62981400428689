body{
	background-color: #000024;
	margin: 0;
}

.top-nav{
	width         	: 100%;
	height			: 130px;
	left          	: 0;
	display 		: flex;
	justify-content : center;
	z-index: 1;
	@include from(m){
		height: 150px;
	}

	&__container{
		display 		: inline-block;
		justify-content : center;
		height        	: var(--header-height);
		width: 100%;
		max-width: 1200px;
		padding: 0 15px;

	}

	&__align{
		padding: 0.8rem;
		@include from(s){
			margin-left: -0.9rem;
		}
	}

	&__link{
		align-self: center;
		
	}
	&__logo{
		height: 4rem;
		width: auto;
		display: block;
		margin: 0 auto;
	@include from(l){
		height: 6.1rem;
		}
	}
	&__logoCrypto{
		width: auto;
		height: 1rem;
		margin: 0 auto;
	@include from(l){
		height: 3.1rem;
		}
	}
	&__menu{
		@include fromTo(s,lg){
			position 		: fixed;
			padding 		: 0 10px;
			height 			: 100vh;
			width 			: 260px;
			background 		: #232323;
			color			: #fff;
			transition 		: right .5s;
			overflow-y 		: auto;
			border-top 		: 1px solid #DFE0E0;
			border-left 	: 1px solid #DFE0E0;
			top 			: var(--header-height);
			right 			: -260px;
			&.is-active {
				right 		: 0;
			}
		}
		@include from(lg){
			align-self: center;
			margin: 1.2rem 0;
			padding: 0;
		}
	}
	&__li{
		color: #fff;
		margin-left: 1em;
		list-style-type: none;
		display: inline-block;
		border-right: 2px solid #232323;
		@include fromTo(s,lg){
			color: #fff;
			display: block;
			padding: 10px 0;
			margin: 0;
		}
		a{
			margin-right: 1rem;
			width: 10vh;
			text-decoration: none;
			}
		
	}
	&__anchor{
		@include fromTo(s,lg){
			display: block;
		}
	}
	&__buttons{
		display: flex;
		align-items: center;
		gap: 0.3rem;   
		
		.btn{
			color: white;
		}
		.btn__login{
			background-color: #2fcf00;
			border: 1px solid #2fcf00;
			padding: 0.4rem 0.7rem 0.2rem;
			border-radius: 4px;
		}
	
		.btn__register{
			background-color: #2fcf00;
			border: 1px solid #2fcf00;
			padding: 0.4rem 0.7rem 0.2rem;
			border-radius: 4px;
		}
	}
	&__mb-iconMenu {
		position: relative;
		align-self: center;
		top: 3px;
		padding: 0;
		span{
			&.hamburger-inner{
				background: black;
				&::after{}
				&::before{}
				&::after, &::before{
					background: black;
				}
			}
		}
		&:focus {
			outline: 0 !important;
			-moz-outline-style: none !important;
			outline: thin dotted;
			outline: 5px auto -webkit-focus-ring-color;
			outline-offset: -2px;
		}
		@include from(lg){
			display: none;
		}
	}

}

