.section-banca{
	display: block;
	background: #ff6600;
	justify-content: center;
	align-items: center;
	max-width: 1200px;
	margin: 1rem auto;
	padding: 0.8rem;
	@include from(m){
		display: flex;
		padding: 0 2rem;
	}
	&__title{
		min-width: 20%;
	}
}
h1 {
	color: #000;
	text-align: center;
	margin: 0;
	font-size: 16px;
}
.containter-banca{
	display: flex;
	justify-content: center;
	align-items: center;
}
.tnsBanca {
	
	&__content {
		// margin-bottom: 10px;

		span {
			color: white;
		}

		img {
			width: 50px;
			height: 50px;
			margin: 0.5rem auto;
		}

	}
}
