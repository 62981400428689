.login-container {

	.header-login {
		button#closeLogin {
			background-color: transparent;
			border          : none;
			text-align      : end;
			float           : right;
		}
	
	}

	.login {
		@include from(m){
		margin		 :0 auto; 
		padding      : 24px;
		max-height	 : 400px;
		max-width    : 500px;
		}
		border-radius: 5px;
		margin		 :0 auto; 
		padding      : 24px;
		width        : auto;
		max-height	 : 400px;
		text-align   : center;
		color        : #fff;

		label{
			padding: 10px;
		}
		.form-group {
			width: 100%;
			
			input.form-control{
				text-align: center;
				padding: 10px;
			}
		}
		
		.buttons{
			display: flex;
		}

		::placeholder{
			color: #fff;
		}

		
		button.btn.btn-success.btn-block.idt {
			background: linear-gradient(45deg, rgba(119,0,118,1) 13%, rgba(215,14,213,1) 90%);
			margin-top: 10px;
			padding: 8px;
			color: #fff;
			font-weight: 800;
			width: 100%;
		}
		a.signup{
			text-decoration: none;
			background: radial-gradient(circle, rgba(0,166,200,1) 0%, rgba(7,111,138,1) 100%);
			margin-top: 10px;
			padding: 8px;
			color: #fff;
			font-weight: 800;
			width: 100%;
			margin-left: 5px;
		}


	.form-group {
				display       : flex;
				flex-direction: column;
			}

	input {
				background-color: transparent;
				border-radius   : 7px;
				color: #fff;
				border          : 2px solid #ffffff8f;
			}
		



	}
}


.active {
	display: flex;
	justify-content: center;
}